<template>
    <div class="bg">
        <div class="screen-left">
            <div class="screen-left-top">
                <!-- <div v-if="muteValue" class="screen-left-top-position" @click="mute">
                    <img src="@/assets/icon/mute.png" class="mute-img" title="静音">
                </div>
                <div v-if="!muteValue" class="screen-left-top-position" @click="mute">
                    <img src="@/assets/icon/unmute.png" class="mute-img" title="取消静音">
                </div> -->
                <div class="screen-left-iframe">
                    <iframe scrolling="no" id="my-iframe" width="100%" height="100%" :src="h5PageUrl" autoplay></iframe>
                </div>
            </div>
            <div class="screen-left-bottom">
                <div class="screen-left-bottom-title">实时评论</div>
                <div v-for="(item,index) in comment" :key="index" class="screen-left-bottom-box">
                    <span class="screen-left-bottom-p">{{item.user_info ? item.user_info.nickname : '微信用户'}}: </span>{{item.content}}
                </div>
            </div>
        </div>
        <div class="screen-between">
            <div class="screen-between-box">
                <div class="screen-between-title"><Input type="text" placeholder="请输入标题" /></div>
                <div class="amplify" @click="amplify"><img src="@/assets/icon/fullscreen.png" class="amplify"></div>
            </div>
            <div class="screen-between-money">
                <div>
                    <div class="screen-between-money-box-p">累计成交金额</div>
                    <div class="screen-between-money-h">
                        <span class="screen-between-money-s">￥</span>{{order_money}}
                    </div>
                </div>
                <div class="screen-between-money-num">
                    <div class="screen-between-money-num-p">订单数</div>
                    <span class="screen-between-money-num-h">{{num}}</span>
                </div>
            </div>
            <div class="screen-between-num">
                <div class="screen-between-num-title">数据总览</div>
                <div class="screen-between-num-box">
                    <div class="screen-between-num-item" v-for="(item,index) in screeningList" :key="index">
                        <span v-if="item.type == 1">秒杀数</span>
                        <span v-if="item.type == 2">种草数</span>
                        <span v-if="item.type == 3">门票数</span>
                        <span v-if="item.type == 4">拼团数</span>
                        <span v-if="item.type == 5">定金数</span>
                        <span v-if="item.type == 6">预约数</span>
                        <span v-if="item.type == 7">曝光量</span>
                        <span v-if="item.type == 8">报名数</span>
                        <span>{{item.total}}</span>
                    </div>
                </div>
            </div>
            <div class="screen-between-bottom">
                <div class="screen-between-money-p">商品销售榜TOP10</div>
                <Table :columns="columnsgoods" :data="datagoods">
                    <template slot-scope="{row}" slot="good_name">
                        <div class="columnsgoods-box">
                            <img class="columnsgoods-img" :src="row.order.shop_active.cover.path" alt="">
                            <div>{{row.good_name}}</div>
                        </div>
                    </template>
                    <template slot-scope="{row}" slot="money">
                        <div class="screen-color">{{row.money}}</div>
                    </template>
                </Table>
            </div>
        </div>
        <div class="screen-right">
            <div class="screen-right-top">
                <div class="screen-right-top-title">实时订单</div>
                <div v-for="(item,index) in orderList" :key="index" class="screen-right-top-box">
                    <div v-if="index == 0" class="screen-right-top-position">最新订单</div>
                    <img v-if="item.shop_active.cover" :src="item.shop_active.cover.path" alt="404" class="screen-right-logo">
                    <img v-else src="" alt="暂无" class="screen-right-logo">
                    <div class="screen-right-top-div">
                        <div v-if="item.project_json">商品名称: {{item.project_json[0].name}}</div>
                        <div>客户昵称: {{item.niackname}}</div>
                        <div>所属员工: 经理人</div>
                        <div>订单金额: <span class="screen-color">￥{{item.pay_money}}</span></div>
                        <div>下单时间: {{item.created_at}}</div>
                    </div>
                </div>
            </div>
            <div class="screen-right-bottom">
                <div class="screen-right-bottom-title">员工成交排行榜</div>
                <Table :columns="columnsStaff" :data="dataStaff">
                    <template slot-scope="{row}" slot="staff">
                        <div class="columnsStaff-box">
                            <img src="@/assets/head_man.png" alt="" class="columnsStaff-img">
                            <div v-if="row.staff">{{row.staff.name}}</div>
                        </div>
                    </template>
                    <template slot-scope="{row}" slot="pay_money">
                        <div class="screen-color">{{row.pay_money}}</div>
                    </template>
                    <template slot-scope="{row}" slot="shop">
                        <div v-if="row.shop">{{row.shop.name}}</div>
                    </template>
                </Table>
            </div>
        </div>
    </div>
</template>

<script>
import {screenIndex, screenOrderList, screenStaffOrder, screenGoodsNum, screenComment} from '@/api/index'
    export default {
        data() {
            return {
                liveId: '',
                userId: '',
                adminId: '',
                shopId: '',
                h5PageUrl: '',
                order_money:'',
                num:'',
                orderList:[],
                comment:[],
                columnsgoods:[
                    {title: '品相名称',slot: 'good_name',align:'center'},
                    {title: '品相成交数',key: 'num',align:'center'},
                    {title: '成交金额',slot: 'money',align:'center'}
                ],
                datagoods: [],
                columnsStaff: [
                    {title: '排名',type: 'index',align:'center',width:'70px'},
                    {title: '员工',slot: 'staff',align:'center',width:'110px'},
                    {title: '成交金额',slot: 'pay_money',align:'center',width:'100px'},
                    {title: '成交单数',key: 'num',align:'center',width:'100px'},
                    {title: '店铺',slot: 'shop',align:'center',width:'70px'}
                ],
                dataStaff: [],
                // 静音参数
                muteValue: false,
                screeningList: []
            }
        },
        mounted(){
            this.screenIndex()
            this.screenOrderList()
            this.screenStaffOrder()
            this.screenGoodsNum()
            this.screenComment()
            setTimeout(() => {
                //2秒后执行代码
                this.h5PageUrl = `https://meiye.meiyebang.com.cn/h5/#/?id=1&shop_id=${this.shopId}&admin_id=${this.adminId}&live_id=${this.liveId}`
                // this.h5PageUrl = `http://localhost:8083/h5/#/?id=1&shop_id=21&admin_id=1&live_id=8`
            }, 2000);
        },
        methods: {
            // 实时评论
            screenComment(){
                screenComment().then(res => {
                    this.comment = res.data
                })
            },
            // 商品销量
            screenGoodsNum(){
                screenGoodsNum().then(res => {
                    this.datagoods = res.data
                })
            },
            // 员工成交销量
            screenStaffOrder(){
                screenStaffOrder().then(res => {
                    console.log('员工成交销量', res.data)
                    this.dataStaff = res.data
                })
            },
            // 累计成交、数据总览
            screenIndex(){
                screenIndex().then(res => {
                    this.order_money = res.data.order_money
                    this.num = res.data.num
                    this.screeningList = res.data.orders_num
                })
            },
            // 实时订单
            screenOrderList(){
                screenOrderList().then(res => {
                    this.liveId = res.data.live_info.id
                    this.adminId = res.data.live_info.admin_id
                    this.shopId = res.data.shop_id
                    this.orderList = res.data;
                    this.$delete(this.orderList, 'live_info');
                    this.$delete(this.orderList, 'shop_id');
                })
            },
            // 静音设置
            mute(){
                this.muteValue =! this.muteValue
            },
            // 放大按钮
            amplify(){
                const elem = document.documentElement;
                this.boostValue = !this.boostValue
                if(this.boostValue){
                    this.controlEle = false
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen();
                    } else if (elem.mozRequestFullScreen) {
                        elem.mozRequestFullScreen();
                    } else if (elem.webkitRequestFullscreen) {
                        elem.webkitRequestFullscreen();
                    } else if (elem.msRequestFullscreen) {
                        elem.msRequestFullscreen();
                    }
                }else{
                    this.controlEle = false
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozCancelFullScreen) {  /* Firefox */
                        document.mozCancelFullScreen();
                    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) { /* IE/Edge */
                        document.msExitFullscreen();
                    }
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.columnsStaff-box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.columnsStaff-img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 5px;
}
.columnsgoods-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.columnsgoods-img{
    width: 80px;
    height: 60px;
    border-radius: 8px;
    margin-right: 5px;
}
.screen-between-money-s{
    font-size: 40px;
}
.screen-between-money-box-p{
    text-align: center;
    font-size: 20px;
    color: #fff;
}
.screen-color{
    color: #fdc318;
}
.screen-right-top-div{
    display:flex;
    flex-direction:column;
    color:#fff;
}
.screen-between-num-item span{
    font-size:18px;
}
.screen-between-num-item{
    display:flex;
    flex-direction:column;
    align-items:center;
    color:#fff;
    width: 25%;
    margin:3vh 0;
}
.screen-between-num-box{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
}
.screen-between-num-title{
    width: 100%;
    color:#fff;
    text-align:center;
    font-size:20px;
}
.screen-between-money-num-p{
    font-size: 18px;
    line-height: 35px;
}
.screen-between-money-num-h{
    line-height: 35px;
    font-size: 32px;
}
.screen-between-money-num{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    height: 125px;
    width: 40%;
    background-color: rgba(0, 0, 0, .05);
    border-radius: 16px;
}
.screen-between-money-h{
    font-size: 60px;
    font-weight: 600;
    text-align: center;
    margin: 5px 0;
    color: #fff;
}
.screen-between-money-p{
    font-size: 20px;
    color: #fff;
}
.screen-left-bottom-p{
    color:#bfbbbb;
}   
.screen-between-box{
    display:flex;
    align-items:center;
}
.screen-left-iframe{
    display: block;
    border-radius: 16px;
    width: 300px;
    height: 534px;
    background-color: #000;
}
.mute-img{
    cursor: pointer;
    margin-top: 5px;
}
.screen-right-logo{
    width: 50px;
    height: 50px;
    border-radius:50%;
    margin-right: 20px;
    margin-left: 10px;
    background-color: gray;
    line-height: 50px;
}
#my-iframe {
    border: none;
}
/* 将滚动条宽度设为1px */
::-webkit-scrollbar {
    width: 2px;
}
/* 将滚动条背景色设为白色 */
::-webkit-scrollbar {
    background-color: #fff;
}
/* 将滚动条上拖动按钮的背景色设为蓝色 */
::-webkit-scrollbar-thumb {
    background-color: #5c84f0;
}
.amplify{
    color: #fff;
    cursor: pointer;
    width: 20px;
}
::v-deep .ivu-input.ivu-input-default{
    border: none;
    padding: 0;
    width: 90%;
    height: 50px;
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    background-color: transparent;
}
/*底色*/
::v-deep .ivu-table td{
    background: #3f4564;
    color: #fff;
}
//表头背景颜色
::v-deep .ivu-table-header thead tr th {
    background: #3f4564 !important;
}
//表头字体颜色
::v-deep.ivu-table-wrapper .ivu-table .ivu-table-header tr th {
    color: #fff !important;
}
// 整个table的下边框和右边框
::v-deep .ivu-table:before,::v-deep .ivu-table:after{
    border: 1px solid #3f4564;
}
// table中所有的横边框
::v-deep .ivu-table td,::v-deep .ivu-table th{
    border: 1px solid #3f4564;
}
::v-deep .ivu-table{
    background-color: #3f4564;
}
.bg{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-color: #373d58;
    padding: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.screen-left{
    width: 300px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.screen-left-top{
    overflow: hidden; /* 确保不会出现滚动条 */
    position: relative;
    border-radius: 16px;
    margin-bottom: 16px;
    min-height: 534px;
}
.screen-left-top-position{
    color: #fff;
    position: absolute;
    top: 3px;
    right: 3px;
    top: 20px;
    cursor: pointer;
}
.screen-left-top-box{
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    height: 80px;
    padding: 10px;
    display: flex;
    flex-direction: row;
}
.screen-left-bottom{
    overflow: auto;
    -webkit-box-flex: 1;
    flex: 1;
    box-sizing: border-box;
    height: calc(100vh - 715px);
    background-color: #3f4564;
    border-radius: 16px;
    width: 100%;
    padding: 11px 16px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
}
.screen-left-bottom-title{
    width: 120px;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    background-color: #7a4fe0;
}
.screen-left-bottom-box{
    width: 90%;
    border-radius: 20px;
    color: #fff;
    background-color: #4e5371;
    padding: 10px;
    margin-top: 5px;
}
.screen-between{
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    padding: 0 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.screen-between-title{
    border: none;
    padding: 0;
    width: 90%;
    height: 50px;
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    background-color: transparent;
}
.screen-between-money{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 183px;
    background: -webkit-gradient(linear, left top, right top, from(#fce38c), color-stop(49%, #f5c5a4), to(#df5ba8));
    background: linear-gradient(90deg, #fce38c, #f5c5a4 49%, #df5ba8);
    border-radius: 16px 16px 16px 16px;
    margin-top: 20px;
    padding-top: 13px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}
.screen-between-num{
    box-sizing: border-box;
    height: 268px;
    background: linear-gradient(128deg, #f4bfd0, #8c70e1 25%, #7b64e4 35%, #734fe0 71%, #6b62e6 85%, #4bacfc);
    border-radius: 16px 16px 16px 16px;
    padding-top: 16px;
    margin-top: 16px;
}
.screen-between-bottom{
    overflow: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #3f4564;
    border-radius: 16px;
    width: 100%;
    height: calc(100% - 553px);
    padding: 16px;
    margin-top: 16px;
}
.screen-right{
    width: 490px;
    height: 100%;
}
.screen-right-top{
    overflow: auto;
    box-sizing: border-box;
    width: 100%;
    height: calc(50% - 5px);
    background-color: #3f4564;
    border-radius: 20px;
    padding: 11px 16px;
    margin-bottom: 10px;
}
.screen-right-top-position{
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px 0 10px 0;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    background-color: #e67ea7;
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
}
.screen-right-top-title{
    width: 120px;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    background-color: #7a4fe0;
}
.screen-right-top-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
    border-radius: 10px;
    background-color: #484e6e;
    margin: 5px 0;
    padding: 10px;
}
.screen-right-bottom{
    overflow: auto;
    box-sizing: border-box;
    width: 100%;
    height: calc(50% - 5px);
    background-color: #3f4564;
    border-radius: 20px;
    padding: 11px 16px;
}
.screen-right-bottom-title{
    width: 150px;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    background-color: #7a4fe0;
}
</style>